import React from "react";
import PropTypes from "prop-types";
import PiratePlunderBG from "../../../assets/images/PiratePlunder.png";
import chipAmountFormatter from "@livecasino/core/helpers/chipAmountFormatter";
import ViewersPlayers from "@livecasino/core/v2/components/ViewersPlayersBetters";

import styles from "./index.module.scss";

const PiratePlunder = ({ currencyCode, totalBetAmount, lastAction = false, viewers, betters, players }) => {
    return (
        <div className={styles.PiratePlunder}>
            <div className={styles.ImageWrapper}>
                <img src={PiratePlunderBG} alt="img" className={styles.Image} />
            </div>
            <div className={styles.Text}>
                {!lastAction && (
                    <>
                        <span className={styles.TotalBet}>{`${chipAmountFormatter(
                            totalBetAmount
                        )} ${currencyCode}`}</span>
                        <div className={styles.ViewersPlayers}>
                            <ViewersPlayers players={players} viewers={viewers} betters={betters} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

PiratePlunder.propTypes = {
    currencyCode: PropTypes.string.isRequired,
    totalBetAmount: PropTypes.number.isRequired,
    players: PropTypes.number.isRequired,
    viewers: PropTypes.number.isRequired,
    betters: PropTypes.number.isRequired,
    lastAction: PropTypes.bool
};

PiratePlunder.defaultProps = {
    lastAction: false
};

export default PiratePlunder;
