import React from "react";
import PropTypes from "prop-types";
import chipAmountFormatter from "@livecasino/core/helpers/chipAmountFormatter";
import ViewersPlayers from "@livecasino/core/v2/components/ViewersPlayersBetters";
import BaccaratBG from "../../../assets/images/Baccarat.png";

import styles from "./index.module.scss";

const Baccarat = ({ currencyCode, totalBetAmount, players, viewers, betters, lastAction = false }) => {
    return (
        <div className={styles.Baccarat}>
            <img src={BaccaratBG} alt="img" className={styles.Image} />
            <div className={styles.Text}>
                {!lastAction && (
                    <>
                        <span className={styles.TotalBet}>{`${chipAmountFormatter(
                            totalBetAmount
                        )} ${currencyCode}`}</span>
                        <div className={styles.PlayersAndSeats}>
                            <ViewersPlayers players={players} viewers={viewers} betters={betters} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

Baccarat.propTypes = {
    currencyCode: PropTypes.string.isRequired,
    totalBetAmount: PropTypes.number.isRequired,
    players: PropTypes.number.isRequired,
    viewers: PropTypes.number.isRequired,
    betters: PropTypes.number.isRequired,
    lastAction: PropTypes.bool
};

Baccarat.defaultProps = {
    lastAction: false
};

export default Baccarat;
