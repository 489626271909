import React, { useEffect, useRef } from "react";
import "components/SideBar/index.module.scss";
import PropTypes from "prop-types";
import MistakeData from "../MistakeData";
import Header from "./Header";

import styles from "./index.module.scss";

function SideBar({ mistakeData = [], totalCountIssuesCount = null, activeIssuesCount = null }) {
    const scrollRef = useRef(null);

    useEffect(() => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }, [mistakeData.length]);

    return (
        <>
            <Header totalCountIssuesCount={totalCountIssuesCount} activeIssuesCount={activeIssuesCount} />
            <div className={`${styles.MistakesContainer} custom-scroll`} ref={scrollRef}>
                {mistakeData.map((mistake, index) => {
                    return (
                        <MistakeData
                            key={index}
                            gameName={mistake.tableName}
                            dealerName={mistake.dealerNickname}
                            mistakeType={mistake.issueType}
                            time={mistake.date}
                            servingPitbossesNames={mistake.servingPitbossesNames}
                            issueSolvedPitbossName={mistake.issueSolvedPitbossName}
                            mistakeState={mistake.issueState}
                        />
                    );
                })}
            </div>
        </>
    );
}

SideBar.propTypes = {
    mistakeData: PropTypes.array,
    totalCountIssuesCount: PropTypes.number,
    activeIssuesCount: PropTypes.number
};

SideBar.defaultProps = {
    mistakeData: [],
    totalCountIssuesCount: null,
    activeIssuesCount: null
};

export default SideBar;
