import React from "react";
import PropTypes from "prop-types";
import { momentFormatter } from "../../hooks/useVideoWallData/helpers";
import { DATE_FORMAT, ACTION_STATES } from "../../shared/constants";

import styles from "./index.module.scss";

function MistakeData({
    time = "",
    mistakeType = "",
    gameName = "",
    servingPitbossesNames = [],
    issueSolvedPitbossName = "",
    dealerName = "",
    mistakeState = ""
}) {
    let color;
    if (mistakeState === ACTION_STATES.active) {
        color = "var(--vw-red)";
    } else if (mistakeState === ACTION_STATES.processing) {
        color = "var(--vw-orange)";
    } else {
        color = "var(--vw-green)";
    }

    let pitbossData;
    if (issueSolvedPitbossName !== null) {
        pitbossData = issueSolvedPitbossName;
    } else {
        pitbossData = servingPitbossesNames.join(", ");
    }
    const stylesObj = { color };

    return (
        <div className={styles.MistakeData} style={stylesObj}>
            <p className={styles.TimeMistakeType}>
                {momentFormatter(time, DATE_FORMAT)} {mistakeType}
            </p>
            <p className={styles.GameName}>{gameName}</p>
            <p className={styles.DealerName}>D: {dealerName}</p>
            <p className={styles.PitBosses}>P: {pitbossData}</p>
        </div>
    );
}

MistakeData.propTypes = {
    time: PropTypes.string,
    mistakeType: PropTypes.string,
    gameName: PropTypes.string,
    servingPitbossesNames: PropTypes.array,
    issueSolvedPitbossName: PropTypes.string,
    dealerName: PropTypes.string,
    mistakeState: PropTypes.string
};

MistakeData.defaultProps = {
    time: "",
    mistakeType: "",
    gameName: "",
    servingPitbossesNames: [],
    issueSolvedPitbossName: "",
    dealerName: "",
    mistakeState: ""
};

export default MistakeData;
