import React from "react";
import PropTypes from "prop-types";
import { ACTION_STATES, ACTION_DISPLAY_NAMES_BY_TYPE } from "../../shared/constants";
import classNames from "classnames";
import Roulette from "./Roulette";
import BlackJack from "./BlackJack";
import Baccarat from "./Baccarat";
import DragonTiger from "./DragonTiger";
import PiratePlunder from "./PiratePlunder";
import SicBo from "./SicBo";

import styles from "./index.module.scss";

const Table = ({ gameId, tableName, dealerNickname, pitbosses, lastAction, height, width, fontSize, renderGame }) => {
    const activeState = lastAction?.state === ACTION_STATES.active;
    const isLastAction = lastAction && lastAction.type !== null;

    const TableClasses = classNames({
        [styles.TableWrapper]: true,
        [styles.ErrorTableWrapper]: activeState
    });

    const BorderedContainerClasses = classNames({
        [styles.BorderedContainer]: true,
        [styles.ErrorBorderedContainer]: activeState
    });
    const FooterClasses = classNames({
        [styles.Footer]: true,
        [styles.ErrorFooter]: activeState
    });

    const DealerAndPitbossesWrapperClasses = classNames({
        [styles.DealerAndPitbossesWrapper]: true,
        [styles.ErrorDealerAndPitbossesWrapper]: activeState
    });

    return (
        <div
            className={TableClasses}
            style={{
                width: `${width}px`,
                height: `${height}px`,
                fontSize: `${fontSize}px`
            }}
        >
            {isLastAction && (
                <div className={BorderedContainerClasses}>
                    {lastAction && (
                        <div className={styles.MistakeText}>{ACTION_DISPLAY_NAMES_BY_TYPE[lastAction.type]}</div>
                    )}
                </div>
            )}

            <div className={styles.Header}>
                <span className={styles.GameId}>
                    <span>ID:</span>
                    <span> {gameId}</span>
                </span>
                <span className={styles.GameName}>
                    <span className={styles.TableNamWrapper}>{tableName}</span>
                </span>
            </div>
            {renderGame()}
            <div className={FooterClasses}>
                <div className={styles.DealerNameWrapper}>
                    <p className={DealerAndPitbossesWrapperClasses}>Dealer</p>
                    <p className={styles.Text}>{dealerNickname}</p>
                </div>
                <div className={styles.PitbossesWrapper}>
                    <div className={DealerAndPitbossesWrapperClasses}>Pitboss</div>
                    <div className={styles.Text}>
                        <span>{pitbosses.join(", ")}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

Table.propTypes = {
    gameId: PropTypes.number.isRequired,
    tableName: PropTypes.string.isRequired,
    pitbosses: PropTypes.array,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    fontSize: PropTypes.number.isRequired,
    lastAction: PropTypes.object,
    renderGame: PropTypes.func.isRequired,
    dealerNickname: PropTypes.string.isRequired
};

Table.defaultProps = {
    warning: null,
    danger: null,
    pitbosses: [],
    lastAction: null,
    availableSeats: null
};

Table.Roulette = Roulette;
Table.BlackJack = BlackJack;
Table.Baccarat = Baccarat;
Table.DragonTiger = DragonTiger;
Table.PiratePlunder = PiratePlunder;
Table.SicBo = SicBo;

export default Table;
