export const INITIAL_VIDEOWALL_STATE = "INITIAL_VIDEOWALL_STATE";
export const DEALER_CHANGED = "DEALER_CHANGED";
export const ACTION_STATE_CHANGED = "ACTION_STATE_CHANGED";
export const ERROR = "ERROR";
export const DISCONNECT = "DISCONNECT";
export const TOTAL_BET_AMOUNT_OR_CURRENCY_CHANGE = "TOTAL_BET_AMOUNT_OR_CURRENCY_CHANGE";
export const PLAYERS_COUNT_CHANGED = "PLAYERS_COUNT_CHANGED";
export const GAME_ID_CHANGED = "GAME_ID_CHANGED";
export const TABLE_NAME_CHANGED = "TABLE_NAME_CHANGED";
export const ACTION_ADDED = "ACTION_ADDED";
export const TABLE_ADDED = "TABLE_ADDED";
export const TABLE_REMOVED = "TABLE_REMOVED";
export const PITBOSSES_COUNT_CHANGED = "PITBOSSES_COUNT_CHANGED";
export const ACTIONS_COUNT_CHANGED = "ACTIONS_COUNT_CHANGED";
export const RESET_ACTIONS = "RESET_ACTIONS";
