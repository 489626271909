import { useEffect } from "react";
import useCustomReducer from "@livecasino/core/hooks/useCustomReducer";
import useSignalR from "@livecasino/core/hooks/useSignalR";
import reducer from "./reducer";
import * as types from "./types";
import { VIDEO_WALL_SERVER_URL } from "../../shared/constants";
import { INITIAL_STATE } from "./InitialState";
import setHubConnectionLogger from "@livecasino/core/helpers/setHubConnectionLogger";

function useVideoWallData({ getAsyncToken }) {
    const [state, dispatch] = useCustomReducer(reducer, INITIAL_STATE, true);

    const { hubConnection, connectionStatus, areListenersSetRef } = useSignalR({
        getAsyncToken,
        url: VIDEO_WALL_SERVER_URL
    });

    useEffect(() => {
        if (!hubConnection || areListenersSetRef.current) {
            return;
        }
        setHubConnectionLogger(hubConnection, "VideoWall");

        hubConnection.on("InitialVideoWallState", data => {
            const { games, issues, issuesCounts } = data;

            dispatch({
                type: types.INITIAL_VIDEOWALL_STATE,
                payload: { games, issues, issuesCounts }
            });
        });

        hubConnection.on("DealerChanged", data => {
            const { tableId, dealerNickname } = data;

            dispatch({
                type: types.DEALER_CHANGED,
                payload: { tableId, dealerNickname }
            });
        });

        hubConnection.on("TotalBetAmountOrCurrencyChanged", data => {
            const { tableId, totalBetAmount, currencyCode } = data;

            dispatch({
                type: types.TOTAL_BET_AMOUNT_OR_CURRENCY_CHANGE,
                payload: { tableId, totalBetAmount, currencyCode }
            });
        });

        hubConnection.on("PlayersCountChanged", data => {
            const { tableId, players, viewers, betters } = data;

            dispatch({
                type: types.PLAYERS_COUNT_CHANGED,
                payload: { tableId, players, viewers, betters }
            });
        });
        hubConnection.on("ActionStateChanged", data => {
            const { tableId, type, state: mistakeState } = data;

            dispatch({
                type: types.ACTION_STATE_CHANGED,
                payload: { tableId, type, state: mistakeState }
            });
        });

        hubConnection.on("GameIdChanged", data => {
            const { tableId, gameId } = data;

            dispatch({
                type: types.GAME_ID_CHANGED,
                payload: { tableId, gameId }
            });
        });

        hubConnection.on("TableNameChanged", data => {
            const { tableId, tableName } = data;

            dispatch({
                type: types.TABLE_NAME_CHANGED,
                payload: { tableId, tableName }
            });
        });
        hubConnection.on("PitbossesCountChanged", data => {
            const { tableId, pitbosses } = data;

            dispatch({
                type: types.PITBOSSES_COUNT_CHANGED,
                payload: { tableId, pitbosses }
            });
        });

        hubConnection.on("ActionsCountChanged", data => {
            const { totalCount, activeIssuesCount } = data;

            dispatch({
                type: types.ACTIONS_COUNT_CHANGED,
                payload: { totalCount, activeIssuesCount }
            });
        });

        hubConnection.on("ResetActions", () => {
            dispatch({
                type: types.RESET_ACTIONS
            });
        });

        hubConnection.on("TableAdded", data => {
            const { game } = data;

            dispatch({
                type: types.TABLE_ADDED,
                payload: { game }
            });
        });

        hubConnection.on("TableRemoved", data => {
            const { tableId } = data;

            dispatch({
                type: types.TABLE_REMOVED,
                payload: { tableId }
            });
        });

        hubConnection.on("ActionAdded", data => {
            const {
                date,
                issueType,
                issueState,
                tableName,
                dealerNickname,
                servingPitbossesNames,
                issueSolvedPitbossName
            } = data;

            dispatch({
                type: types.ACTION_ADDED,
                payload: {
                    date,
                    issueType,
                    issueState,
                    tableName,
                    dealerNickname,
                    servingPitbossesNames,
                    issueSolvedPitbossName
                }
            });
        });
        areListenersSetRef.current = true;
    }, [hubConnection, areListenersSetRef, dispatch]);

    useEffect(() => {
        if (!hubConnection || !connectionStatus.isConnected || !areListenersSetRef.current) {
            return;
        }
        hubConnection.invoke("GetInitialState");
    }, [areListenersSetRef, dispatch, connectionStatus.isConnected, hubConnection]);

    useEffect(() => {
        dispatch({
            type: types.ERROR,
            payload: connectionStatus.error
        });
    }, [connectionStatus.error, dispatch]);

    return {
        ...state
    };
}

export default useVideoWallData;
