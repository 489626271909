import * as types from "./types";
import { INITIAL_STATE } from "./InitialState";

export default function reducer(state, action) {
    switch (action.type) {
        case types.INITIAL_VIDEOWALL_STATE: {
            const { games, issues, issuesCounts } = action.payload;

            return {
                games,
                issues,
                issuesCounts,
                loading: false,
                error: false
            };
        }

        case types.DEALER_CHANGED: {
            const { tableId, dealerNickname } = action.payload;

            const games = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, dealerNickname };
                }
                return game;
            });

            return {
                ...state,
                games
            };
        }

        case types.TOTAL_BET_AMOUNT_OR_CURRENCY_CHANGE: {
            const { tableId, totalBetAmount, currencyCode } = action.payload;

            let updatedGames = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, totalBetAmount, currencyCode };
                }
                return game;
            });

            return {
                ...state,
                games: updatedGames
            };
        }

        case types.PLAYERS_COUNT_CHANGED: {
            const { tableId, players, viewers, betters } = action.payload;

            const games = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, players, viewers, betters };
                }
                return game;
            });

            return {
                ...state,
                games
            };
        }

        case types.ACTION_STATE_CHANGED: {
            const { tableId, type, state: mistakeState } = action.payload;
            const games = state.games.map(game => {
                if (game.tableId === tableId) {
                    let newAction = { type, state: mistakeState };
                    game = { ...game, lastAction: newAction };
                }
                return game;
            });

            return {
                ...state,
                games
            };
        }

        case types.GAME_ID_CHANGED: {
            const { tableId, gameId } = action.payload;
            const games = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, gameId };
                }
                return game;
            });
            return {
                ...state,
                games
            };
        }

        case types.TABLE_NAME_CHANGED: {
            const { tableId, tableName } = action.payload;
            const games = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, tableName };
                }
                return game;
            });
            return {
                ...state,
                games
            };
        }

        case types.PITBOSSES_COUNT_CHANGED: {
            const { tableId, pitbosses } = action.payload;
            const games = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, pitbosses: pitbosses };
                }
                return game;
            });
            return {
                ...state,
                games
            };
        }

        case types.ACTIONS_COUNT_CHANGED: {
            const { totalCount, activeIssuesCount } = action.payload;
            let issuesCounts = {
                totalCount,
                activeIssuesCount
            };

            return {
                ...state,
                issuesCounts
            };
        }

        case types.RESET_ACTIONS: {
            let issuesCounts = {
                ...state.issuesCounts,
                totalCount: 0,
                activeIssuesCount: 0
            };

            return {
                ...state,
                issuesCounts,
                issues: []
            };
        }

        case types.TABLE_ADDED: {
            const { game } = action.payload;
            const games = [...state.games, game];
            return {
                ...state,
                games
            };
        }

        case types.TABLE_REMOVED: {
            const { tableId } = action.payload;
            const games = state.games.filter(game => game.tableId !== tableId);
            return {
                ...state,
                games
            };
        }

        case types.ACTION_ADDED: {
            const {
                date,
                issueType,
                issueState,
                tableName,
                dealerNickname,
                servingPitbossesNames,
                issueSolvedPitbossName
            } = action.payload;
            const issues = [
                ...state.issues,
                {
                    date,
                    issueType,
                    issueState,
                    tableName,
                    dealerNickname,
                    servingPitbossesNames,
                    issueSolvedPitbossName
                }
            ];
            return {
                ...state,
                issues
            };
        }

        case types.ERROR: {
            const error = action.payload;
            return {
                ...state,
                error
            };
        }

        case types.DISCONNECT: {
            return INITIAL_STATE;
        }

        default:
            return state;
    }
}
