import React from "react";
import PropTypes from "prop-types";
import RouletteBG from "../../../assets/images/Roulette.png";
import chipAmountFormatter from "@livecasino/core/helpers/chipAmountFormatter";
import ViewersPlayers from "@livecasino/core/v2/components/ViewersPlayersBetters";

import styles from "./index.module.scss";

const Roulette = ({ currencyCode, totalBetAmount, players, lastAction = false, viewers, betters }) => {
    return (
        <div className={styles.Roulette}>
            <div className={styles.ImageWrapper}>
                <img src={RouletteBG} alt="img" className={styles.Image} />
            </div>
            <div className={styles.Text}>
                {!lastAction && (
                    <>
                        <span className={styles.TotalBet}>{`${chipAmountFormatter(
                            totalBetAmount
                        )} ${currencyCode}`}</span>
                        <div className={styles.ViewersPlayers}>
                            <ViewersPlayers players={players} viewers={viewers} betters={betters} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

Roulette.propTypes = {
    currencyCode: PropTypes.string.isRequired,
    totalBetAmount: PropTypes.number.isRequired,
    players: PropTypes.number.isRequired,
    viewers: PropTypes.number.isRequired,
    betters: PropTypes.number.isRequired,
    lastAction: PropTypes.bool
};

Roulette.defaultProps = {
    lastAction: false
};

export default Roulette;
