let VIDEO_WALL_SERVER_BASE_URL = process.env.REACT_APP_PUBLIC_VIDEO_WALL_SERVER_URL;
let AUTH_URL = process.env.REACT_APP_PUBLIC_AUTH_URL;

if (window.location.origin === process.env.REACT_APP_LOCAL_ORIGIN) {
    AUTH_URL = process.env.REACT_APP_LOCAL_AUTH_URL;
    VIDEO_WALL_SERVER_BASE_URL = process.env.REACT_APP_LOCAL_VIDEO_WALL_SERVER_URL;
} else if (window.location.origin === process.env.REACT_APP_LB_ORIGIN) {
    VIDEO_WALL_SERVER_BASE_URL = process.env.REACT_APP_LB_VIDEO_WALL_SERVER_URL;
}
const VIDEO_WALL_SERVER_URL = VIDEO_WALL_SERVER_BASE_URL + "/videowall";
export { AUTH_URL, VIDEO_WALL_SERVER_URL };

export const ACTION_STATES = {
    idle: "Idle",
    processing: "Processing",
    active: "Active"
};

export const ACTION_TYPES = {
    mistake: "Mistake",
    card: "Card",
    chat: "Chat",
    cardChange: "CardChange",
    change: "Change",
    technical: "Technical",
    talk: "Talk",
    PG: "PG"
};

export const ACTION_DISPLAY_NAMES_BY_TYPE = {
    [ACTION_TYPES.mistake]: "Mistake",
    [ACTION_TYPES.card]: "Card",
    [ACTION_TYPES.chat]: "Chat",
    [ACTION_TYPES.cardChange]: "Card Change",
    [ACTION_TYPES.change]: "Change",
    [ACTION_TYPES.technical]: "Technical",
    [ACTION_TYPES.talk]: "Talk",
    [ACTION_TYPES.PG]: "PG"
};
export const GameTypes = {
    Blackjack: "Blackjack",
    Roulette: "Roulette",
    Baccarat: "Baccarat",
    DragonTiger: "DragonTiger",
    PiratePlunder: "PiratePlunder",
    SicBo: "SicBo"
};

export const CLASSIC_BJ_AVAILABLE_SEATS = 7;

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const ERROR_TRANSLATIONS = {
    NO_AVAILABLE_CONNECTION: "No available connection",
    LOOKS_LIKE_YOU_HAVE_NO_INTERNET_CONNECTION: "Looks like you have no internet connection",
    CHECK_YOUR_CONNECTION_AND_TRY_AGAIN: "Check your connection and try again",
    YOUR_SESSION_HAS_EXPIRED: "Your session is expired.",
    PLEASE_RELOAD_THE_PAGE: "Please reload the page",
    ACCESS_DENIED: "You have no permission",
    SOMETHING_WENT_WRONG: "Something went wrong",
    YOU_HAVE_NO_ACCESS: "You have no access"
};
